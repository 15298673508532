import React, { Component } from 'react';  
import { Link,Redirect } from 'react-router-dom';
import logo from '../reglogo.png' 
import api from '../api';

class ForgotPassword extends Component { 

    constructor(props) {
        super(props); 
        this.state = {
          email: '', 
          errorMessage: '', 
          loaded: false, 
        };
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault(); 
        this.setState({ loaded: true}); 
        api.get('forgotpassword/'+this.state.email)
        .then(result => {  
            this.setState({ loaded: false}); 
            if(result.data){
                if(result.data == "email not exist"){ 
                    this.setState({ errorMessage: result.data }); 
                }
                else{
                    this.setState({ errorMessage: result.data }); 
                }
            } 
        }); 
    }

    render() {
        return (
            <div className="App">
            <div className="appBody">
            <img className='img-responsive' src={logo} style={{marginBottom:'1em'}}></img> 
            <h3 className="pageTitle">PROTON DATA DEFENDER RECORD GENERATION SERVICE</h3>
                {
                    this.state.loaded ?  
                    <div id="cover-spin"></div>
                    : <div></div>
                }
                <div className="panel" style={{width:475}}> 
                <form onSubmit={this.onSubmit} className="form">
                <h2 align="center" className="modal-title"> Forgot your password?</h2>
                <h5 className="information">
                    Don't worry! Retrieving your password is easy.<br></br> 
                    Just type the email you associated with your account and hit 'send'
                </h5>
                <div className="form-group"> 
                    <label className="control-label">Email</label>
                    <input type="text" name="email" placeholder="Email Address" required="required" className="form-control" onChange={this.onChange}/> 
                </div> 
                {
                    this.state.errorMessage == "email not exist" ? 
                        <div className="form-group" style={{color:"red",fontSize:16}}>Email address does not exist in system.</div> :
                        this.state.errorMessage == "email send successfully" ?  
                        <div className="form-group" style={{color:"green",fontSize:16}}>Please check your email account.</div> 
                        : this.state.errorMessage != "" ? 
                        <div className="form-group" style={{color:"red",fontSize:16}}>Something went wrong while sending email.</div>
                        :
                        <div></div>
                }
                <button type="submit" className="btn btn-success btn-block btn-lg">Send</button>
                </form>
                </div> 
                <div className="text-center small"><Link className="green" to={`/`}>Back to login</Link></div>
            </div> 
            </div> 
        );
    }
}

export default ForgotPassword;