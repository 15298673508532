import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import logo from '../reglogo.png'
import api from '../api';

var self;
 
class SignUp extends Component {  
    constructor() {
        super();
        this.state = {
            name: '', 
            password: '',
            confirm_password: '',
            isNewsLetter: false, 
            email: ''
        };
        self = this;
    }

    onChange = (e) => {
        const state = this.state
        if(e.target.type == "checkbox")
            state[e.target.name] = e.target.checked;
        else
            state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault(); 
        if(this.validatePassword()){
            console.log(this.state.email);
            api.get('getbyemail/'+this.state.email)
            .then(res => {  
                if(res.data){
                    alert("Email address is already exist please use different email address.");
                    return;
                }
                api.post('users', this.state)
                .then((result) => { 
                    console.log(result.data);
                    if(result.data != null){    
                        this.props.history.push("/register/"+result.data._id);
                    }
                    else{
                        alert("Enter valid user name and password");
                    }
                });
            });  
        }
    }

    validatePassword(){
        var isValid = true; 
         var confirm_password = document.getElementById("confirm_password");  
        if(self.state.password != self.state.confirm_password) {
          confirm_password.setCustomValidity("Passwords Don't Match");
          isValid = false;
        } else {
          confirm_password.setCustomValidity('');
        }
        return isValid;
    }

    render() {
        return (
          <div className="App">
            <div className="appBody">
            <img className='img-responsive' src={logo} style={{marginBottom:'1em'}}></img> 
            <h3 className="pageTitle">PROTON DATA DEFENDER RECORD GENERATION SERVICE</h3>
                <div className="panel" style={{width:'320px'}}> 
                    <form onSubmit={this.onSubmit} className="form">
                        <h2 align="center" className="modal-title"> Sign Up</h2>
                        <hr></hr>
                        <div className="form-group">  
                            <input type="text" placeholder="Name" name="name" required="required" className="form-control"  onChange={this.onChange}/> 
                        </div>
                        <div className="form-group">  
                            <input type="email" name="email"  required="required" placeholder="Email Address" className="form-control"  onChange={this.onChange}/> 
                        </div>
                        <div className="form-group">  
                            <input type="password" name="password"  id="password" required="required" placeholder="Password" className="form-control"  onChange={this.onChange}/> 
                        </div>
                        <div className="form-group">  
                            <input type="password" name="confirm_password" id="confirm_password" onKeyUp={this.validatePassword} required="required" placeholder="Confirm Password" className="form-control"  onChange={this.onChange}/> 
                        </div>
                        <div className="form-group">  
                            <label> <input type="checkbox"  onChange={this.onChange} name="isNewsLetter"/> RECEIVE NEWSLETTERS</label> 
                        </div>
                        <button type="submit" className="btn btn-success btn-block btn-lg">SUBMIT</button>
                    </form>
                </div>  
                <div className="text-center small">Already have an account? <Link className="green" to={`/`}>Login</Link></div>
            </div>
        </div>
        );
    }
}

export default SignUp;
