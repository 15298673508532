import React, { Component } from 'react';  
import { Link,Redirect } from 'react-router-dom';
import api from '../api';

var self;

class ActivateUsers extends Component {  

    constructor() {  
        super();
        this.state = {
            activeUsers: [],
            name: '', 
            password: '',
            confirm_password: '', 
            email:''
        }
        self = this;  
    }

    onChange = (e) => {
        const state = this.state
        if(e.target.type == "checkbox")
            state[e.target.name] = e.target.checked;
        else
            state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault(); 
        if(this.validatePassword()){
            console.log(this.state.email);
            api.get('getbyemail/'+this.state.email)
            .then(res => {  
                if(res.data){
                    alert("Email address is already exist please use different email address.");
                    return;
                }
                api.post('activeusers', {username:this.state.email,activateStatus:true})
                .then((result) => {    
                    api.post('users', this.state)
                    .then((result) => {  
                        if(result.data != null){    
                            alert("User Created Successfully");
                            self.fetchAllData();
                        }
                        else{
                            alert("Enter valid user name and password");
                        }
                    });
                });  
            });  
        }
    }

    validatePassword(){
        var isValid = true; 
         var confirm_password = document.getElementById("confirm_password");  
        if(self.state.password != self.state.confirm_password) {
          confirm_password.setCustomValidity("Passwords Don't Match");
          isValid = false;
        } else {
          confirm_password.setCustomValidity('');
        }
        return isValid;
    }

    componentDidMount() {
        self.fetchAllData();
    }

    fetchAllData(){
        self.setState({ name: ''});  
        self.setState({ password: ''});  
        self.setState({ confirm_password: ''});  
        self.setState({ email: ''});   
        api.get('activeusers')
        .then(res => {   
            self.setState({ activeUsers: res.data});    
        });
      } 

    render() {
        return (
            <div>
                {
                    (localStorage.getItem("usersession") != null && localStorage.getItem("usersession") != undefined) ?
                        <div className="row container"  style={{padding:'3em'}}> 
                            <div className="col-md-12 float-left">
                                <div className="col-md-6 float-left">
                                    {/* <h2 className="adminPageTitle">ADMINISTRATION PANEL</h2> */}
                                    <div className="pageContent">
                                        <h2 className="adminPageTitle">CONNECT PAYMENT GATEWAY :</h2> 
                                        <div className="col-md-12 float-left">
                                            <div className="col-md-5 float-left">
                                                <div className="card text-center">
                                                    <h4>Paypal</h4>
                                                    <Link  className="btn btn-primary" style={{color:'white'}} to={`/paypal-gateway-configuration`}>CONNECT</Link>  
                                                </div>
                                            </div>
                                            <div className="col-md-5 float-left">
                                                <div className="card text-center" >
                                                    <h4>Stripe</h4>
                                                    <Link  className="btn btn-primary" style={{color:'white'}} to={`/stripe-gateway-configuration`}>CONNECT</Link>  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 float-left" style={{marginTop:20}}>
                                            <div className="col-md-8 float-left">
                                                <div className="card">
                                                    <h4 style={{marginBottom:20}}>Create User</h4>
                                                    {/* <Link  className="btn btn-primary" style={{color:'white'}} to={`/paypal-gateway-configuration`}>CONNECT</Link>   */}
                                                    <form onSubmit={this.onSubmit} className="form"> 
                                                        <div className="form-group">  
                                                            <input type="text" placeholder="Name" value={this.state.name} name="name" required="required" className="form-control"  onChange={this.onChange}/> 
                                                        </div>
                                                        <div className="form-group">  
                                                            <input type="email" name="email"  value={this.state.email}  required="required" placeholder="Email Address" className="form-control"  onChange={this.onChange}/> 
                                                        </div>
                                                        <div className="form-group">  
                                                            <input type="password" name="password"  value={this.state.password}  id="password" required="required" placeholder="Password" className="form-control"  onChange={this.onChange}/> 
                                                        </div>
                                                        <div className="form-group">  
                                                            <input type="password" name="confirm_password" value={this.state.confirm_password}  id="confirm_password" onKeyUp={this.validatePassword} required="required" placeholder="Confirm Password" className="form-control"  onChange={this.onChange}/> 
                                                        </div> 
                                                        <button type="submit" className="btn btn-success btn-block btn-lg">SUBMIT</button>
                                                    </form>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 float-left"> 
                                    <h2 className="adminPageTitle" align="right"><Link  style={{color:'white'}} to={`/`}>DEVICE MANAGEMENT</Link></h2>
                                    <div className="pageContent table-responsive"> 
                                        <table className="table userTable">
                                            {this.state.activeUsers.map(activateUser => 
                                                <tr key={activateUser._id}>
                                                    <td>{activateUser.username}</td>
                                                    <td>
                                                        {
                                                            activateUser.activateStatus ? <button disabled className="btn btn-success">ACTIVE</button> : <button disabled className="btn btn-danger">FAILED</button>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            activateUser.activateStatus ? <span></span> : <button className="btn btn-primary">SEND REMINDER</button>
                                                        }
                                                    </td>
                                                </tr>
                                            )} 
                                        </table> 
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    :
                        <Redirect to='/'  />
                }
            </div>
           
           
        );
    }
}

export default ActivateUsers