import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../api';

class StripePaymentGateWayConfig extends Component {
    constructor() {
        super();
        this.state = {
            stripe_id: '',
            stripeData: {
                enable_stripe: false,
                title: '',
                description: '',
                enable_testmode: false,
                test_pub_key: '',
                test_secret_key: '',
                test_plan_id: '',
                enable_inline_card_form: false,
                statement_descriptor: '',
                enable_capture_charge: false,
                enable_3d_secure: false,
                enable_stripe_modal: false,
                enable_payment_buttons: false,
                payment_button_type: '',
                payment_button_theme: '',
                payment_button_height: '',
                enable_payment_savecards: false,
                enable_log_debug: false
            }
        };
    }

    componentDidMount() {
        api.get('stripe-config/')
            .then(res => {
                if (res.data != null && res.data != undefined) {
                    this.setState({ stripeData: res.data });
                    this.setState({ stripe_id: res.data._id });
                }
            });
    }

    onChange = (e) => {
        const state = this.state.stripeData;
        if (e.target.type == "checkbox")
            state[e.target.name] = e.target.checked;
        else
            state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.stripe_id == '') {
            api.post('stripe-config/', this.state.stripeData)
                .then((result) => {
                    //  this.props.history.push("/"); 
                    alert("Stripe configuration saved successfully!!!");
                });
        }
        else {
            api.put('stripe-config/' + this.state.stripe_id, this.state.stripeData)
                .then((result) => {
                    alert("Stripe configuration saved successfully!!!");
                    //  this.props.history.push("/"); 
                });
        }

    }

    render() {
        return (
            <div>
                {
                    (localStorage.getItem("usersession") != null && localStorage.getItem("usersession") != undefined) ?
                        <div className="row container" style={{ marginLeft: 0, paddingBottom: 15 }}>
                            <div className="col-md-12 float-left">
                                <div className="col-md-8 float-left">
                                    <h2 className="adminPageTitle">STRIPE CONFIGURATION</h2>
                                </div>
                                <div className="col-md-4 float-left" style={{ textAlign: 'right' }}>

                                    <h2 className="adminPageTitle"> <Link style={{ color: 'white' }} to={`/admin-panel`}>ADMIN HOME</Link></h2>
                                </div>
                            </div>
                            <div className="col-md-12 float-left" style={{ marginTop: '1em', backgroundColor: '#f3ca3e', padding: '2em', color: 'black' }}>
                                <form className="form-horizontal" onSubmit={this.onSubmit}>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <div className="col-md-12 float-left">
                                                <h4>Stripe</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="gray col-md-12 float-left">
                                            <div className="col-md-12 float-left">
                                                Stripe works by adding payment fields on the checkout and then sending the details to Stripe for verification.Sign Up for a Stripe account, and get your Stripe account keys
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Enable/Disable</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_stripe" checked={this.state.stripeData.enable_stripe} onChange={this.onChange}></input> Enable Stripe</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Title</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="title" value={this.state.stripeData.title} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Description</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="description" value={this.state.stripeData.description} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-12 float-left">Webhook Endpoints</label>
                                        </div>
                                    </div>
                                    <div className='form-group row gray' style={{ marginBottom: '1em' }}>
                                        <div className="col-md-12 float-left">
                                            <div className="col-md-12 float-left">
                                                You must add the following webhook endpoint <a href="https://www.protondata.com/?wc-api=wc_stripe" style={{ backgroundColor: 'lightgray', color: "black" }} target="_blank">https://www.protondata.com/?wc-api=wc_stripe</a> to your Stripe Account Settings. This will enable you to receive notifications on the charge statuses.
                                        </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Test mode</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_testmode" checked={this.state.stripeData.enable_testmode} onChange={this.onChange}></input> Enable Test Mode</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Test Publisable Key</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="test_pub_key" value={this.state.stripeData.test_pub_key} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Test Secret Key</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="test_secret_key" value={this.state.stripeData.test_secret_key} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Annual Stripe Plan Id</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="test_plan_id" value={this.state.stripeData.test_plan_id} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Inline Credit Card Form</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_inline_card_form" checked={this.state.stripeData.enable_inline_card_form} onChange={this.onChange}></input> Inline Credit Card Form</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Statement descriptor</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="statement_descriptor" value={this.state.stripeData.statement_descriptor} onChange={this.onChange}></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Capture</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_capture_charge" checked={this.state.stripeData.enable_capture_charge} onChange={this.onChange}></input> Capture charge immediately</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">3D Secure</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_3d_secure" checked={this.state.stripeData.enable_3d_secure} onChange={this.onChange}></input> Require 3D Secure when applicable</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Stripe Modal Checkout</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_stripe_modal" checked={this.state.stripeData.enable_stripe_modal} onChange={this.onChange}></input> Enable Stripe Checkout</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Payment Request Buttons</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_payment_buttons" checked={this.state.stripeData.enable_payment_buttons} onChange={this.onChange}></input> Enable Payment Request Buttons.(Apple Pay/Chrome Payment Request API) <br></br>
                                                    By using Apple Pay, you are agree to Stripe and Apple's terms of service.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Payment Request Button Type</label>
                                            <div className="col-md-6 float-left">
                                                <select name="payment_button_type" onChange={this.onChange} value={this.state.stripeData.payment_button_type} >
                                                    <option value="Buy">Buy</option>
                                                    <option value="Sell">Sell</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Payment Request Button Theme</label>
                                            <div className="col-md-6 float-left">
                                                <select name="payment_button_theme" onChange={this.onChange} value={this.state.stripeData.payment_button_theme} >
                                                    <option value="Dark">Dark</option>
                                                    <option value="Light">Light</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Payment Request Button  Height</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="text" name="payment_button_height" onChange={this.onChange} value={this.state.stripeData.payment_button_height} ></input></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Save Cards</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_payment_savecards" onChange={this.onChange} checked={this.state.stripeData.enable_payment_savecards} ></input> Enable Payment via Save Cards</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 float-left">
                                            <label className="control-label col-md-2 float-left">Logging</label>
                                            <div className="col-md-6 float-left">
                                                <label><input type="checkbox" name="enable_log_debug" onChange={this.onChange} checked={this.state.stripeData.enable_log_debug} ></input> Log debug messages</label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Save Changes</button>
                                </form>
                            </div>
                        </div>
                        :
                        <Redirect to='/' />
                }
            </div>

        )
    }
}

export default StripePaymentGateWayConfig
