import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import api from '../api';
// var StripeObj = new window.Stripe('pk_test_ZnF8AZMYJU1WHYqvnzhPdDIT');
// var stripe = require('stripe-client')('sk_test_PymViNYCl2I3aqNKLd4qnTWg');

class SignUpDone extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            user: {}
        }; 
    }

    componentDidMount() {  
        api.get('users/'+this.props.match.params.id)
          .then(res => { 
            this.setState({ user: res.data }); 
        }); 
    
      }

    //   onChange = (e) => {
    //     const state = this.state.user 
    //     state[e.target.name] = e.target.value;
    //     this.setState({task:state});
    //   }

    // onSubmit = (e) => {  
    //     e.preventDefault();
       
    //     //  this.onPayment();
    //      var  
    //         cardInfo = {
    //           number: '4242424242424242',
    //           exp_month: '02',
    //           exp_year: '21',
    //           cvc: '999',
    //           name: 'Billy Joe'
    //         } 
    //       var elements = StripeObj.elements();
    //       var card = elements.create('card');
    //       card.mount("#cardNumber");
    //     //   card.mount("#cvv");
    //     //   card.mount("#expiration_date");
    //     //   card.mount("#name"); 
    //       console.log(card);

    //     StripeObj.createToken(card,cardInfo, function (status, response) {
    //         console.log(status);
    //         console.log(response);
    //         // axios.put(process.env.REACT_APP_API_URL+'users/'+this.props.match.params.id, this.state.user)
    //         //   .then((result) => {
    //         //     this.props.history.push("/"); 
    //         // });
    //     });
    // }

    // async onPayment() {
    //     // var cardDate = new Date(this.state.user.expiration_date);
    //     // var cardInformation = {
    //     //     card:{
    //     //         number: this.state.user.cardNumber,
    //     //         exp_month:  (cardDate.getMonth() + 1),
    //     //         exp_year: cardDate.getFullYear(),
    //     //         cvc:  this.state.user.cvv
    //     //     } 
    //     // }
    //     // var information = {
    //     //     card: {
    //     //       number: '4242424242424242',
    //     //       exp_month: '02',
    //     //       exp_year: '21',
    //     //       cvc: '999',
    //     //       name: 'Billy Joe'
    //     //     }
    //     //   }
    // //  await stripe.createToken(information).then(function(data,e){

    // //         console.log(data); 
    // //     });
    //     // var token = card.id;
    //     // console.log(card);
    //     // console.log(token);
    //     // send token to backend for processing
    // }

    onToken = (token, addresses) => {
        console.log(addresses);
        console.log(token);
        // TODO: Send the token information and any other
        // relevant information to your payment process
        // server, wait for the response, and update the UI
        // accordingly. How this is done is up to you. Using
        // XHR, fetch, or a GraphQL mutation is typical.
      };

    render(){
        return (
            <StripeCheckout
                stripeKey="pk_test_ZnF8AZMYJU1WHYqvnzhPdDIT"
                token={this.onToken}
            />
            // <div className="App">
               
            //   <div className="appBody"  style={{padding:'1em 0'}}>
            //   <div className="register-panel" style={{width:'350px'}}>
            //   <form onSubmit={this.onSubmit} className="form">
            //   <div className="form-group">  
            //         <label>YOUR NAME</label><span>Your first name and last name</span>
            //         <input type="text" placeholder="Name" className="form-control" name="name" value={this.state.user.name}  onChange={this.onChange}/> 
            //     </div>
            //     <div className="form-group">
            //         <label>CARD NUMBER</label><span>The long number on your card</span>
            //         <input type="text" placeholder="Card number" className="form-control" id="cardNumber"  name="cardNumber" onChange={this.onChange}/> 
            //     </div>
            //     <div className="form-group">
            //         <label>CVV</label><span>The 3/4 digit security code on your card</span>
            //         <input type="text" placeholder="CVV" className="form-control" name="cvv"  onChange={this.onChange}/> 
            //     </div>
            //     <div className="form-group">
            //         <label>EXPIRATION DATE</label>
            //         <input type="date" placeholder="Expiration Date" className="form-control"  name="expiration_date" onChange={this.onChange}/> 
            //     </div>
            //     <hr style={{marginTop:'1em',marginBottom:'0.5em'}}></hr>
            //     <div className="form-group">
            //         <label>ADDRESS LINE 1</label>
            //         <input type="text" placeholder="Address line 1" className="form-control" name="address_line1"  onChange={this.onChange}/> 
            //     </div>
            //     <div className="form-group">
            //         <label>ADDRESS LINE 2</label>
            //         <input type="text" placeholder="Address line 2" className="form-control"  name="address_line2" onChange={this.onChange}/> 
            //     </div>
            //     <div className="form-group"> 
            //     <div className="row"  style={{margin:'0px'}}> 
            //         <div className="col-md-12 float-left" style={{padding:'0px'}}>
            //             <div className="col-md-6 float-left"  style={{paddingLeft:'0px'}}>
            //                 <label>CITY</label>
            //                 <input type="text" placeholder="City" className="form-control"  name="city" onChange={this.onChange}/> 
            //             </div>
            //             <div className="col-md-6 float-left"  style={{padding:'0px'}}>
            //                 <label>STATE</label>
            //                 <input type="text" placeholder="State" className="form-control"  name="state" onChange={this.onChange}/> 
            //             </div>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="form-group"  > 
            //      <div className="row"  style={{margin:'0px'}}> 
            //         <div className="col-md-12 float-left" style={{padding:'0px'}}>
            //             <div className="col-md-6 float-left"  style={{paddingLeft:'0px'}}>
            //                 <label>ZIP CODE</label>
            //                 <input type="text" placeholder="Zip Code" className="form-control"  name="pincode" onChange={this.onChange}/> 
            //             </div>
            //             <div className="col-md-6 float-left"  style={{padding:'0px'}}>
            //                 <label>COUNTRY</label>
            //                 <input type="text" placeholder="Country" className="form-control"  name="country" onChange={this.onChange}/> 
            //             </div>
            //         </div>
            //         </div>
            //     </div>
            //     <button type="submit" className="btn btn-success btn-block btn-lg">SUBMIT</button>
            //   </form>
            //   </div>
            //   </div>
            // </div>
        );
    }  
}

export default  SignUpDone
